<template>
  <div>
    <div v-if="compInfo">
      <FactoryView v-if="compInfo && compInfo.type==1" :comp="compInfo"></FactoryView>
      <DesignView v-if="compInfo && compInfo.type==2" :comp="compInfo"></DesignView>
      <SaleView v-if="compInfo && compInfo.type==3" :comp="compInfo"></SaleView>
    </div>
  </div>
</template>

<script>
import { getCompanyInfo } from "@/service/sale";
import FactoryView from "./factory/View.vue";
import DesignView from "./desgin/View.vue";
import SaleView from "./sale/View.vue";
export default {
  components: {
    FactoryView, DesignView, SaleView
  },
  data() {
    return {
      compInfo: null
    };
  },
  async created() {
    const id = this.$route.params.id;
    getCompanyInfo(id).then(res => {
      this.compInfo = res;
    });
  }
};
</script>

<style scoped lang="less">

</style>